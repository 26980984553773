import { render, staticRenderFns } from "./CostBearerClassicPay.vue?vue&type=template&id=c02c1576&"
import script from "./CostBearerClassicPay.vue?vue&type=script&lang=ts&"
export * from "./CostBearerClassicPay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports