

















































import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import FscInputConditional from "@/components/Field/FscInputConditional.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { requiredIf } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";

@Component({
  components: { Validation, AbortButton, FscInputConditional, FilterButton, FscModal },
})
export default class CostBearerPayFilterModal extends Vue {
  public name = "CostBearerPayFilterModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop({ type: Array, default: () => [] })
  public licenceClassesOptions!: Array<string>;

  public licenseClasses = [];
  public finishedEducation: boolean | null = null;
  public condition = "";
  public balance = null;

  protected ok(): void {
    const data = {
      licenseClasses: this.licenseClasses.length ? this.licenseClasses : null,
      finishedEducation: this.finishedEducation,
    };
    if (this.balance) {
      switch (this.condition) {
        case "<":
          Object.assign(data, {
            balanceUnder: Number(this.balance),
          });
          break;
        case ">":
          Object.assign(data, {
            balanceOver: Number(this.balance),
          });
          break;
        case "=":
          Object.assign(data, {
            balance: Number(this.balance),
          });
          break;
      }
    }

    this.$emit("ok", data);
  }

  public clearFilter(cancel: () => void): void {
    this.licenseClasses = [];
    this.finishedEducation = null;
    this.condition = "";
    this.balance = null;
    this.$emit("filterActive", false);
    cancel();
    this.ok();
  }

  protected okFilter(ok: () => void): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.$emit("filterActive", true);
    ok();
    this.ok();
  }

  protected get conditionOptions(): Array<string> {
    return [">", "=", "<"];
  }

  protected get finishedEducationOptions(): Array<any> {
    return [
      {
        label: this.$t("general.no"),
        value: false,
      },
      {
        label: this.$t("general.yes"),
        value: true,
      },
    ];
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      condition: {
        required: requiredIf((val: any) => {
          return Boolean(val.balance);
        }),
      },
      balance: {
        required: requiredIf((val: any) => {
          return Boolean(val.condition);
        }),
      },
    };
  }
}
