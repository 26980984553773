




import { Component, Vue, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";

@Component({
  components: {
    TheMask,
  },
})
export default class FscInputConditional extends Vue {
  public name = "FscInputConditional";

  @Prop()
  public value!: any;

  public hexTokens = {
    C: {
      pattern: /[>=<]/,
      transform: (v: string) => v.toLocaleUpperCase(),
    },
  };

  public onInput(event: string): void {
    this.$emit("input", event);
  }
}
