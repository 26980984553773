var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-modal',{attrs:{"modal-id":_vm.modalId,"hide-header":false,"body-class":"mx-4 mb-4 pt-0","cancel-btn-label":""},scopedSlots:_vm._u([{key:"cancel",fn:function(ref){
var cancel = ref.cancel;
return [_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.clearFilter(cancel)}}})]}},{key:"ok",fn:function(ref){
var ok = ref.ok;
return [_c('filter-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":_vm.$t('students.filters'),"filtered-is-active":true},on:{"click":function($event){return _vm.okFilter(ok)}}})]}}])},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.license_class", 1))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.licenceClassesOptions,"searchable":true,"multiple":true},model:{value:(_vm.licenseClasses),callback:function ($$v) {_vm.licenseClasses=$$v},expression:"licenseClasses"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"5"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.balance", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.condition},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.conditionOptions,"clearable":true},model:{value:(_vm.condition),callback:function ($$v) {_vm.condition=$$v},expression:"condition"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"7"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500 invisible"},[_vm._v(" "+_vm._s(_vm.$tc("general.balance", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.balance},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","min":"0"},model:{value:(_vm.balance),callback:function ($$v) {_vm.balance=$$v},expression:"balance"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"5"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("automatic_payment.education_finished", 1))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.finishedEducationOptions,"clearable":true,"label":"label","reduce":function (v) { return v.value; }},model:{value:(_vm.finishedEducation),callback:function ($$v) {_vm.finishedEducation=$$v},expression:"finishedEducation"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }