



















































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Table from "@/components/Table.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Actions from "@/components/Actions.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import ShoppingCartButton from "@/components/Button/ShoppingCartButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import { namespace } from "vuex-class";
import PaymentRequestButton from "@/components/Button/PaymentRequestButton.vue";
import FileCheckButton from "@/components/Button/FileCheckButton.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import StudentCol from "@/views/AutomaticPaymentsOverview/StudentCol.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import AutomaticPaymentMixin from "@/mixins/AutomaticPaymentMixin";
import _ from "lodash";
import GenerateStudentInvoiceModal from "@/views/AutomaticPaymentsOverview/GenerateStudentInvoiceModal.vue";
import UnfinishedInvoiceModal from "@/views/AutomaticPaymentsOverview/UnfinishedInvoiceModal.vue";
import { CLASSIC_PAY } from "@/constants/PaymentWorkflow";
import CostBearerPayFilterModal from "@/views/CostBearers/CostBearerPayFilterModal.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import PaymentWorkflowRequestMixin from "@/mixins/Request/PaymentWorkflowRequestMixin";
import { ICostBearerStudentListFilter } from "@/interfaces/ICostBearerStudentListFilter";
import { v4 as uuidv4 } from "uuid";
import GenerateFinalInvoiceConfirmationModal from "@/views/AutomaticPaymentsOverview/GenerateFinalInvoiceConfirmationModal.vue";
import FileInvoiceDollarButton from "@/components/Button/FileInvoiceDollarButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import InvoiceDocumentPreview from "@/views/AutomaticPaymentsOverview/InvoiceDocumentPreview.vue";
import DraftInvoiceButton from "@/components/Button/DraftInvoiceButton.vue";
import CostBearerBookingInvoicePreview from "@/views/CostBearers/CostBearerBookingInvoicePreview.vue";

const AppModule = namespace("app");
const LicenseClassModule = namespace("license-class");
const CostBearerModule = namespace("cost-bearer");

@Component({
  components: {
    FscModal,
    FileInvoiceDollarButton,
    GenerateFinalInvoiceConfirmationModal,
    CostBearerPayFilterModal,
    UnfinishedInvoiceModal,
    GenerateStudentInvoiceModal,
    TextDecimal,
    StudentCol,
    Hint,
    FilterAndSearch,
    FileCheckButton,
    PaymentRequestButton,
    Actions,
    Table,
    FscPageHeader,
    FscSimpleCard,
    VerticalDivider,
    ShoppingCartButton,
    PrintButton,
    FileCsvButton,
    MailButton,
    InvoiceDocumentPreview,
    DraftInvoiceButton,
    CostBearerBookingInvoicePreview,
  },
})
export default class CostBearerClassicPay extends mixins(AutomaticPaymentMixin, PaymentWorkflowRequestMixin) {
  public name = "CostBearerClassicPay";

  public fields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "student",
      label: "",
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$tc("general.license_class", 1),
      sortable: false,
    },
    {
      key: "description",
      label: this.$t("general.description"),
      sortable: false,
    },
    {
      key: "actualBalance",
      label: this.$t("automatic_payment.actual_balance"),
      sortable: false,
    },
    {
      key: "previewBalance",
      label: this.$t("automatic_payment.preview_balance"),
      sortable: false,
    },
    {
      key: "sigma",
      label: "Sigma",
      sortable: false,
    },
    {
      key: "lastPaymentRequestDate",
      label: this.$t("automatic_payment.date_of_last_request_of_payment"),
      sortable: false,
    },
    {
      key: "finishedEducation",
      label: this.$t("automatic_payment.education_finished"),
      sortable: false,
    },
    {
      key: "finalInvoiceDate",
      label: this.$t("automatic_payment.date_of_final_invoice"),
      sortable: false,
    },
  ];

  protected filtered = "";
  protected openBalance = false;
  protected filteredIsActive = false;

  protected generateCustomBookingInvoiceModalId = "generate-custom-booking-invoice-modal-id";

  protected generateFinalInvoiceConfirmationModalId = "cost-bearer-classic-pay-generate-final-invoice-confirmation-modal-id" + this.uuid;

  @AppModule.Action("setSelectedCostBearerClassicPayStudents")
  public setSelectedCostBearerClassicPayStudents: any;

  @AppModule.Action("setSelectedCostBearerSingleStudent")
  public setSelectedCostBearerSingleStudent: any;

  @LicenseClassModule.Action("findAll")
  public licenseClassFindAll!: (options: ICrudOptions) => Promise<void>;

  @LicenseClassModule.Getter("getDataList")
  public licenseClasses!: Array<string>;

  @CostBearerModule.Action("generateClassicPayCustomBookingInvoice")
  public generateClassicPayCustomBookingInvoice!: (options: { costBearerId: number; costBearerCustomBookingIds: Array<number> }) => Promise<void>;

  @CostBearerModule.Getter("getGenerateCostBearerClassicPayCustomBookingInvoiceSuccess")
  public getGenerateCostBearerClassicPayCustomBookingInvoiceSuccess!: boolean;

  protected filter: ICostBearerStudentListFilter | null = null;

  protected modalId = "generate-final-invoice-student-modal" + this.uuid;

  protected unfinishedEdModal = "unfinished-modal" + this.uuid;

  protected costBearerId: any = null;

  protected costBearerPayFilterModalId = "cost-bearer-pay-filter-modal" + this.uuid;

  protected costBearerDocumentId = "cost-document-preview" + this.uuid;

  protected onChangeFilter(): void {
    (this.$refs.costBearerClassicPay as Table).onSearch(this.filtered);
  }

  protected async onClickPaymentRequest(): Promise<void> {
    console.log(this.selectedResources);
    // filter all except 'other cost' item as they are invoiced otherwise
    const filteredSelectedResources = this.selectedResources?.filter((resource: any) => !!resource.studentEducationId === true);
    if (filteredSelectedResources?.length < this.selectedResources?.length) {
      this.$toasted.info(this.$t("messages.other_cost_zb_prevent").toString());
    }
    await this.setSelectedCostBearerClassicPayStudents([...filteredSelectedResources]);
    if (this.selectedResources.length !== 0) {
      await this.$router.push({
        name: "CostBearerPaymentRequest",
        params: { costBearerId: String(this.id) },
      });
    }
  }

  protected async fetchCostBearerStudents(): Promise<void> {
    this.filter = {
      costBearerId: this.costBearerId,
      paymentWorkflowId: CLASSIC_PAY,
      openBalance: this.openBalance,
    };

    await this.fetchCostBearerStudentList(this.filter);
  }

  protected get id(): number {
    const { id } = this.$route.params;
    return Number(id);
  }

  public mounted(): void {
    this.costBearerId = Number(this.$route.params.id);
    this.fetchCostBearerStudents();
    this.$root.$on("before-destroyed-component", (props: any) => {
      if (_.isBoolean(props.success) && props.success && props.component == this.name) {
        this.fetchCostBearerStudents();
      }
    });

    this.licenseClassFindAll({ resource: "license-classes" });
  }

  protected generateInvoicePreview() {
    this.$bvModal.show(this.costBearerDocumentId);
  }

  protected async generateFinalInvoice(): Promise<void> {
    this.$bvModal.hide(this.costBearerDocumentId);
    await this.generateFinalInvoices(this.selectedResourceIds);
    if (this.generateFinalInvoicesSuccess) {
      await this.fetchCostBearerStudents();
      this.$toasted.success(this.$t("messages.save_success").toString());
    }
  }

  protected onChangeOpenBalance(): void {
    this.fetchCostBearerStudents();
  }

  protected get disableDepositInvoiceButton() {
    if (this.selectedResources.length !== 0 && this.selectedResources[0].finishedEducation) {
      return true;
    }
    return false;
  }

  protected onClickFilter(): void {
    this.$bvModal.show(this.costBearerPayFilterModalId);
  }

  protected okCostBearerPayFilter(filter: ICostBearerStudentListFilter): void {
    this.filter = filter;
    this.fetchCostBearerStudentList({
      ...filter,
      costBearerId: this.id,
      paymentWorkflowId: CLASSIC_PAY,
      openBalance: this.openBalance,
    });
  }

  protected onCsvExport(): void {
    if (this.filter) {
      this.generateCsvExport(this.filter);
    }
  }

  public filterActive(value: boolean): void {
    this.filteredIsActive = value;
  }

  protected get uuid(): any {
    return uuidv4();
  }

  protected get selectedCustomBookingIds(): { hasEmptyId: boolean; ids: Array<number> } {
    return this.selectedResources.reduce(
      (previousValue: any, currentValue: any) => {
        if (currentValue.costBearerCustomBookingId == null) {
          previousValue.hasEmptyId = true;
        } else {
          previousValue.ids.push(currentValue.costBearerCustomBookingId);
        }
        return previousValue;
      },
      {
        hasEmptyId: false,
        ids: [],
      }
    );
  }

  private onGenerateCustomBookingInvoice(): void {
    if (this.selectedCustomBookingIds.hasEmptyId) {
      const message = this.$tc("messages.invoice_courses", 1);
      this.$toasted.info(message);
    } else {
      this.$bvModal.show(this.generateCustomBookingInvoiceModalId);
    }
  }

  private async okGenerateCustomBookingInvoice(): Promise<void> {
    if (!this.id || !this.selectedCustomBookingIds.ids.length) return;
    await this.generateClassicPayCustomBookingInvoice({
      costBearerId: this.id,
      costBearerCustomBookingIds: this.selectedCustomBookingIds.ids,
    });

    if (this.getGenerateCostBearerClassicPayCustomBookingInvoiceSuccess) {
      this.$bvModal.hide(this.generateCustomBookingInvoiceModalId);
      this.fetchCostBearerStudents();
    }
  }

  protected cancelGenerateCustomBookingInvoice() {
    this.$bvModal.hide(this.generateCustomBookingInvoiceModalId);
  }

  protected createFinalInvoiceConfirmed() {
    this.$bvModal.hide(this.modalId);
  }

  protected async onDraftInvoiceAzrClick(): Promise<void> {
    if (this.selectedResources.length !== 0 && this.selectedResources.length === 1) {
      await this.$router.push({
        name: "CostBearDepositInvoice",
        query: {
          educationId: String(this.selectedResources[0]?.studentEducationId),
          licenseClass: this.selectedResources[0]?.licenseClass,
          firstName: this.selectedResources[0]?.student.firstName,
          lastName: this.selectedResources[0]?.student.lastName,
        },
      });
    } else {
      this.$bvModal.show(this.modalId);
    }
  }

  protected cancelGenerateCostBearerInvoice() {
    this.$bvModal.hide(this.costBearerDocumentId);
  }
}
